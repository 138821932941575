import React from "react";
import Page from "../components/Page";
import { CircularProgress, Grid } from "@mui/material";
import NormalText from "../Texts/NormalText";
import useAppDispatch from "../redux/useAppDispatch";
import useOpenSnackbar from "../redux/useOpenSnackbar";
import useAppSelector from "../redux/useAppSelector";
import {
  clickFavoriteExercise,
  getPopularExercises,
  getStartedExercises,
} from "../redux/exercisesSlice";
import { DrawCard, DrawExerciseCard } from "./Lecons";
import {
  clickFavorite,
  getPopularLecons,
  getStartedLecons,
} from "../redux/leconsSlice";

const Accueil: React.FC = () => {
  const snackbarUse = useOpenSnackbar();
  const dispatch = useAppDispatch();
  const popularExercices = useAppSelector((x) => x.exercises.popular);
  const startedExercices = useAppSelector((x) => x.exercises.started);
  const startedLecons = useAppSelector((x) => x.lecons.started);
  const popularLecons = useAppSelector((x) => x.lecons.popular);
  const pageExerciseLoading = useAppSelector((x) => x.exercises.isLoading);
  const pageLeconLoading = useAppSelector((x) => x.lecons.isLoading);
  const pageLoading = pageExerciseLoading || pageLeconLoading;

  React.useEffect(() => {
    dispatch(getStartedLecons())
      .unwrap()
      .then((res) => {
        return;
      })
      .catch((err) => {
        snackbarUse.error("Impossible de récupérer les leçons commencés.");
      })
      .finally(() => {
        return;
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getStartedExercises())
      .unwrap()
      .then((res) => {
        return;
      })
      .catch((err) => {
        snackbarUse.error("Impossible de récupérer les exercices commencés.");
      })
      .finally(() => {
        return;
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getPopularLecons())
      .unwrap()
      .then((res) => {
        return;
      })
      .catch((err) => {
        snackbarUse.error("Impossible de récupérer les leçons populaires.");
      })
      .finally(() => {
        return;
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getPopularExercises())
      .unwrap()
      .then((res) => {
        return;
      })
      .catch((err) => {
        snackbarUse.error("Impossible de récupérer les exercices populaires.");
      })
      .finally(() => {
        return;
      });
  }, [dispatch]);

  return (
    <Page pageTitle="Accueil">
      {pageLoading ? (
        <>
          <CircularProgress
            style={{
              color: "white",
            }}
          />
        </>
      ) : (
        <Grid container direction="column" wrap="nowrap" gap="30px">
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" gap="15px">
              <Grid item display="grid">
                <NormalText text="Leçons populaires" />
              </Grid>
              <Grid item display="grid">
                {popularLecons?.length == 0 ? (
                  <NormalText
                    text="Aucune leçon populaire."
                    fontSize="14px"
                  />
                ) : (
                  <>
                    <Grid
                      container
                      direction="row"
                      gap="20px"
                      justifyContent={"space-evenly"}
                    >
                      {popularLecons.map((card) => {
                        return (
                          <Grid item key={card._id} display="grid">
                            <DrawCard
                              card={card}
                              onFavoriteClick={() => {
                                if (card?._id) {
                                  dispatch(clickFavorite(card._id));
                                }
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" gap="15px">
              <Grid item display="grid">
                <NormalText text="Exercices populaires" />
              </Grid>
              <Grid item display="grid">
                {popularExercices?.length == 0 ? (
                  <NormalText
                    text="Aucun exercice populaire."
                    fontSize="14px"
                  />
                ) : (
                  <>
                    <Grid
                      container
                      direction="row"
                      gap="20px"
                      justifyContent={"space-evenly"}
                    >
                      {popularExercices.map((card) => {
                        return (
                          <Grid item key={card._id} display="grid">
                            <DrawExerciseCard
                              card={card}
                              onEditClick={undefined}
                              onDeleteClick={undefined}
                              onFavoriteClick={() => {
                                if (card?._id) {
                                  dispatch(clickFavoriteExercise(card._id));
                                }
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" gap="15px">
              <Grid item display="grid">
                <NormalText text="Reprendre mes leçons" />
              </Grid>
              <Grid item display="grid">
                {startedLecons?.length == 0 ? (
                  <NormalText
                    text="Vous n'avez aucun leçon en cours."
                    fontSize="14px"
                  />
                ) : (
                  <>
                    <Grid
                      container
                      direction="row"
                      gap="20px"
                      justifyContent={"space-evenly"}
                    >
                      {startedLecons.map((card) => {
                        return (
                          <Grid item key={card._id} display="grid">
                            <DrawCard
                              card={card}
                              onFavoriteClick={() => {
                                if (card?._id) {
                                  dispatch(clickFavorite(card._id));
                                }
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" gap="15px">
              <Grid item display="grid">
                <NormalText text="Reprendre mes exercices" />
              </Grid>
              <Grid item display="grid">
                {startedExercices?.length == 0 ? (
                  <NormalText
                    text="Vous n'avez aucun exercice en cours."
                    fontSize="14px"
                  />
                ) : (
                  <>
                    <Grid
                      container
                      direction="row"
                      gap="20px"
                      justifyContent={"space-evenly"}
                    >
                      {startedExercices.map((card) => {
                        return (
                          <Grid item key={card._id} display="grid">
                            <DrawExerciseCard
                              card={card}
                              onEditClick={undefined}
                              onDeleteClick={undefined}
                              onFavoriteClick={() => {
                                if (card?._id) {
                                  dispatch(clickFavoriteExercise(card._id));
                                }
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

export default Accueil;
