import React from "react";
import Page from "../components/Page";
import { CircularProgress, Grid } from "@mui/material";
import NormalText from "../Texts/NormalText";
import { AddRounded, DeleteRounded, EditRounded } from "@mui/icons-material";
import ButtonIconWithTooltip from "../components/ButtonIconWithTooltip";
import useAppDispatch from "../redux/useAppDispatch";
import useAppSelector from "../redux/useAppSelector";
import useOpenSnackbar from "../redux/useOpenSnackbar";
import {
  Audio,
  audioTypes,
  DisplayAudio,
  DrawExerciseCard,
  EditAudio,
} from "./Lecons";
import {
  clickFavoriteExercise,
  createExercise,
  getAllExercises,
  swapExercises,
  updateExercise,
} from "../redux/exercisesSlice";
import { GenericPopup } from "../components/Popup/GenericPopup";
import SecondaryButton from "../components/SecondaryButton/SecondaryButton";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import { nanoid } from "@reduxjs/toolkit";
import BasicInput from "../components/BasicInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Colors from "../utils/Colors";
import BasicSelect from "../components/BasicSelect";

export interface IExerciseResponse extends IExercise {
  /* Generated at response */
  nbCompleted: number;
  favorite?: boolean;
}

export interface IExercise {
  _id?: string;
  favoriteUsers?: string[];
  title: string;
  description: string;
  creationDate?: Date;
  updateDate?: Date;
  needSubscription: boolean;
  isPopular: boolean;
  steps: ExerciseStep[];
  congratulationMessage: string;
  startAudio: Audio;
  durationEstimation: number;
}
export interface ResponseLabel {
  _id?: string;
  response: string;
  isGoodResponse: boolean;
}

export interface QuestionLabel {
  _id?: string;
  question: string;
  labels: ResponseLabel[];
}

export interface ExerciseStep {
  _id?: string;
  title: string;
  type: "timer" | "switch" | "liste_responses" | "audio";
  timer?: {
    instructions: string;
    duration: number;
  };
  audio?: Audio;
  questionLabels: QuestionLabel[];
}

const cleanExerciseBeforePut = (exercise: IExercise): IExercise => {
  return {
    _id: exercise._id,
    description: exercise.description,
    title: exercise.title,
    congratulationMessage: exercise.congratulationMessage,
    durationEstimation: exercise.durationEstimation,
    needSubscription: exercise.needSubscription,
    isPopular: exercise.isPopular,
    startAudio: {
      ...exercise.startAudio,
      _id:
        exercise?.startAudio?._id && exercise.startAudio._id.length > 8
          ? exercise.startAudio._id
          : undefined,
    },
    steps: exercise.steps.map((s) => {
      return {
        _id: s?._id && s._id.length > 8 ? s._id : undefined,
        title: s?.title,
        type: s?.type,
        timer: s?.timer,
        audio: s.audio
          ? {
              ...s.audio,
              _id:
                s?.audio?._id && s.audio._id.length > 8
                  ? s.audio._id
                  : undefined,
            }
          : undefined,
        questionLabels: s?.questionLabels?.map((questionLabel) => {
          return {
            ...questionLabel,
            _id:
              questionLabel?._id && questionLabel._id.length > 8
                ? questionLabel._id
                : undefined,
            question: questionLabel?.question,
            labels: questionLabel?.labels?.map((label) => {
              return {
                ...label,
                _id: label?._id && label._id.length > 8 ? label._id : undefined,
              };
            }),
          };
        }),
      };
    }),
  };
};

const cleanExerciseBeforeSend = (step: IExercise): IExercise => {
  return {
    ...step,
    startAudio: {
      ...step.startAudio,
      _id: undefined,
    },
    steps: step.steps.map((s) => {
      return {
        ...s,
        _id: undefined,
        audio: s?.audio
          ? {
              ...s.audio,
              _id: undefined,
            }
          : undefined,
        questionLabels: s?.questionLabels?.map((questionLabel) => {
          return {
            ...questionLabel,
            _id: undefined,
            question: questionLabel?.question,
            labels: questionLabel?.labels?.map((label) => {
              return {
                ...label,
                _id: undefined,
              };
            }),
          };
        }),
      };
    }),
    _id: undefined,
  };
};

const initExerciseStep = (): ExerciseStep => {
  return {
    _id: nanoid(8),
    title: "",
    type: "timer",
    timer: {
      instructions: "",
      duration: 0,
    },
    questionLabels: [],
  };
};

const initResponseLabel = (): ResponseLabel => {
  return {
    _id: nanoid(8),
    isGoodResponse: false,
    response: "",
  };
};

const initQuestionLabel = (): QuestionLabel => {
  return {
    _id: nanoid(8),
    labels: [],
    question: "",
  };
};

const initExercise = (): IExercise => {
  return {
    description: "",
    title: "",
    congratulationMessage: "",
    durationEstimation: 0,
    needSubscription: false,
    isPopular: false,
    startAudio: {
      audio: "",
      title: "",
      lengthInSec: 0,
    },
    steps: [],
  };
};

const CreateOrEditResponseLabel: React.FC<{
  responseLabel?: ResponseLabel;
  isEdit?: boolean;
  onSubmit: (newResponseLabel: ResponseLabel) => void;
  onClose?: () => void;
}> = (props) => {
  const [responseLabel, setResponseLabel] = React.useState<ResponseLabel>(
    initResponseLabel()
  );
  const snackbarOpenUse = useOpenSnackbar();

  React.useEffect(() => {
    if (props.isEdit && props.responseLabel) {
      setResponseLabel(props.responseLabel);
    } else {
      return;
    }
  }, [props.isEdit, props.responseLabel]);

  if (!responseLabel) {
    return <></>;
  }
  return (
    <>
      <GenericPopup
        title={{
          leftPart: props.isEdit ? "Modifier" : "Créer",
          rightPart: "les réponses",
        }}
        onClose={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <>
          {responseLabel && (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              padding="20px"
              gap="20px"
              alignItems={"center"}
            >
              <Grid item display="grid">
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  gap="15px"
                  alignItems={"center"}
                >
                  <Grid item xs display="grid">
                    <BasicInput
                      title="Réponse"
                      colorTitle={"Black"}
                      placeholder=""
                      centerTitle={true}
                      value={responseLabel.response}
                      onChange={(e) => {
                        setResponseLabel((s) => {
                          return { ...s, response: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item display="grid" marginTop="10px">
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      gap="10px"
                      alignItems={"center"}
                    >
                      <Grid item display="grid">
                        <input
                          type="checkbox"
                          checked={responseLabel?.isGoodResponse === true}
                          onClick={(e) => {
                            setResponseLabel((x) => {
                              return {
                                ...x,
                                isGoodResponse: x?.isGoodResponse
                                  ? false
                                  : true,
                              };
                            });
                          }}
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </Grid>
                      <Grid item display="grid">
                        <NormalText
                          text={"Ceci est une bonne réponse."}
                          color="Black"
                          fontSize="14px"
                          fontWeight={400}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display="grid" marginTop="25px">
                  <Grid
                    container
                    direction="row"
                    gap="10px"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item display="grid">
                      <SecondaryButton
                        text={"Annuler"}
                        onClick={() => {
                          if (props.onClose) {
                            props.onClose();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <PrimaryButton
                        text={props.isEdit ? "Modifier" : "Créer"}
                        onClick={() => {
                          if (props.onSubmit) {
                            props.onSubmit(responseLabel);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      </GenericPopup>
    </>
  );
};

const CreateOrEditQuestionLabel: React.FC<{
  questionLabel?: QuestionLabel;
  isEdit?: boolean;
  onSubmit: (newQuestionLabel: QuestionLabel) => void;
  onClose?: () => void;
}> = (props) => {
  const [questionLabel, setQuestionLabel] = React.useState<QuestionLabel>(
    initQuestionLabel()
  );
  const [addResponseOpen, setAddResponseOpen] = React.useState(false);
  const [editResponseOpen, setEditResponseOpen] = React.useState<
    ResponseLabel | undefined
  >(undefined);
  const snackbarOpenUse = useOpenSnackbar();

  React.useEffect(() => {
    if (props.isEdit && props.questionLabel) {
      setQuestionLabel(props.questionLabel);
    } else {
      return;
    }
  }, [props.isEdit, props.questionLabel]);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(questionLabel.labels);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestionLabel((prevLabels) => ({
      ...prevLabels,
      labels: items,
    }));
  };

  if (!questionLabel) {
    return <></>;
  }
  return (
    <>
      <GenericPopup
        title={{
          leftPart: props.isEdit ? "Modifier" : "Créer",
          rightPart: "ma question",
        }}
        onClose={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <>
          {addResponseOpen && (
            <CreateOrEditResponseLabel
              onSubmit={(newResponseLabel) => {
                setQuestionLabel((state) => {
                  return {
                    ...state,
                    labels:
                      !state.labels || state.labels.length === 0
                        ? [newResponseLabel]
                        : [...state.labels, newResponseLabel],
                  };
                });
                setAddResponseOpen(false);
              }}
              responseLabel={undefined}
              isEdit={false}
              onClose={() => setAddResponseOpen(false)}
            />
          )}
          {editResponseOpen && (
            <CreateOrEditResponseLabel
              onSubmit={(newResponseLabel) => {
                setQuestionLabel((state) => {
                  return {
                    ...state,
                    labels:
                      state.labels && state.labels.length > 0
                        ? state.labels.map((label) => {
                            if (label?._id !== newResponseLabel?._id) {
                              return label;
                            }
                            return newResponseLabel;
                          })
                        : [],
                  };
                });
                setEditResponseOpen(undefined);
              }}
              responseLabel={editResponseOpen}
              isEdit={true}
              onClose={() => setEditResponseOpen(undefined)}
            />
          )}
          {questionLabel && (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              padding="20px"
              gap="20px"
              alignItems={"center"}
            >
              <Grid item display="grid">
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  gap="15px"
                  alignItems={"center"}
                >
                  <Grid item xs display="grid">
                    <BasicInput
                      title="Question"
                      colorTitle={"Black"}
                      placeholder=""
                      centerTitle={true}
                      value={questionLabel.question}
                      onChange={(e) => {
                        setQuestionLabel((s) => {
                          return { ...s, question: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <Grid
                      container
                      direction="column"
                      wrap="nowrap"
                      gap="10px"
                      alignItems={"center"}
                    >
                      <Grid item display="grid">
                        <NormalText
                          text="Ajouter une réponse"
                          fontSize="12px"
                          fontWeight={600}
                          color="Black"
                          className="button-nobreak"
                          onClick={() => {
                            setAddResponseOpen(true);
                          }}
                        />
                      </Grid>
                      {questionLabel?.labels &&
                      questionLabel.labels.length > 0 ? (
                        <Grid item display="grid">
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="15px"
                          >
                            {questionLabel?.labels &&
                              questionLabel?.labels?.length > 0 && (
                                <DragDropContext onDragEnd={onDragEnd}>
                                  <Droppable droppableId="reponses">
                                    {(provided: any) => (
                                      <Grid
                                        item
                                        display="grid"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          wrap="nowrap"
                                          gap="15px"
                                        >
                                          {questionLabel.labels.map(
                                            (label, index) => (
                                              <Draggable
                                                key={label._id}
                                                draggableId={label._id as any}
                                                index={index}
                                                isDragDisabled={false}
                                              >
                                                {(provided: any) => (
                                                  <Grid
                                                    item
                                                    display="grid"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <Grid
                                                      container
                                                      direction="row"
                                                      gap="10px"
                                                      justifyContent={
                                                        "space-between"
                                                      }
                                                      alignItems={"center"}
                                                      wrap="nowrap"
                                                    >
                                                      <Grid item display="grid">
                                                        <NormalText
                                                          text={
                                                            label?.response
                                                              ? label?.response
                                                              : "Sans titre"
                                                          }
                                                          color="Black"
                                                          fontSize="14px"
                                                          fontWeight={400}
                                                        />
                                                      </Grid>
                                                      <Grid item display="grid">
                                                        <Grid
                                                          container
                                                          direction="row"
                                                          gap="5px"
                                                        >
                                                          <Grid
                                                            item
                                                            display="grid"
                                                          >
                                                            <ButtonIconWithTooltip
                                                              Icon={
                                                                <EditRounded
                                                                  style={{
                                                                    color:
                                                                      Colors.Black,
                                                                    width:
                                                                      "20px",
                                                                    height:
                                                                      "20px",
                                                                  }}
                                                                />
                                                              }
                                                              onClick={() => {
                                                                setEditResponseOpen(
                                                                  label
                                                                );
                                                              }}
                                                              tooltipText="Modifier"
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            display="grid"
                                                          >
                                                            <ButtonIconWithTooltip
                                                              Icon={
                                                                <DeleteRounded
                                                                  style={{
                                                                    color:
                                                                      Colors.Black,
                                                                    width:
                                                                      "20px",
                                                                    height:
                                                                      "20px",
                                                                  }}
                                                                />
                                                              }
                                                              onClick={() => {}}
                                                              tooltipText="Supprimer"
                                                            />
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                )}
                                              </Draggable>
                                            )
                                          )}
                                          {provided.placeholder}
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              )}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item display="grid">
                          <NormalText
                            text="Aucune réponse."
                            color="Black"
                            fontSize="12px"
                            fontWeight={400}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display="grid" marginTop="25px">
                  <Grid
                    container
                    direction="row"
                    gap="10px"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item display="grid">
                      <SecondaryButton
                        text={"Annuler"}
                        onClick={() => {
                          if (props.onClose) {
                            props.onClose();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <PrimaryButton
                        text={props.isEdit ? "Modifier" : "Créer"}
                        onClick={() => {
                          if (props.onSubmit) {
                            props.onSubmit(questionLabel);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      </GenericPopup>
    </>
  );
};

const CreateOrEditStep: React.FC<{
  step?: ExerciseStep;
  isEdit?: boolean;
  onSubmit: (newStep: ExerciseStep) => void;
  onClose?: () => void;
}> = (props) => {
  const [editAudioOpen, setEditAudioOpen] = React.useState<Audio | undefined>(
    undefined
  );
  const [addQuestionOpen, setAddQuestionOpen] = React.useState(false);
  const [editQuestionOpen, setEditQuestionOpen] = React.useState<
    QuestionLabel | undefined
  >(undefined);
  const [step, setStep] = React.useState<ExerciseStep>(initExerciseStep());
  const snackbarOpenUse = useOpenSnackbar();

  React.useEffect(() => {
    if (props.isEdit && props.step) {
      setStep(props.step);
    } else {
      return;
    }
  }, [props.isEdit, props.step]);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(step.questionLabels);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setStep((prevSteps) => ({
      ...prevSteps,
      questionLabels: items,
    }));
  };

  if (!step) {
    return <></>;
  }
  return (
    <>
      <GenericPopup
        title={{
          leftPart: props.isEdit ? "Modifier" : "Créer",
          rightPart: "mon étape",
        }}
        onClose={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <>
          {editAudioOpen && (
            <EditAudio
              audio={editAudioOpen}
              onClose={() => {
                setEditAudioOpen(undefined);
              }}
              onSubmit={(newAudio) => {
                setStep((e) => {
                  return {
                    ...e,
                    audio: newAudio,
                  };
                });
              }}
            />
          )}
          {addQuestionOpen && (
            <CreateOrEditQuestionLabel
              onSubmit={(newQuestionLabel) => {
                setStep((state) => {
                  return {
                    ...state,
                    questionLabels:
                      !state.questionLabels || state.questionLabels.length === 0
                        ? [newQuestionLabel]
                        : [...state.questionLabels, newQuestionLabel],
                  };
                });
                setAddQuestionOpen(false);
              }}
              questionLabel={undefined}
              isEdit={false}
              onClose={() => setAddQuestionOpen(false)}
            />
          )}
          {editQuestionOpen && (
            <CreateOrEditQuestionLabel
              onSubmit={(newQuestionLabel) => {
                setStep((state) => {
                  return {
                    ...state,
                    questionLabels:
                      state.questionLabels && state.questionLabels.length > 0
                        ? state.questionLabels.map((step) => {
                            if (step?._id !== newQuestionLabel?._id) {
                              return step;
                            }
                            return newQuestionLabel;
                          })
                        : [],
                  };
                });
                setEditQuestionOpen(undefined);
              }}
              questionLabel={editQuestionOpen}
              isEdit={true}
              onClose={() => setEditQuestionOpen(undefined)}
            />
          )}
          {step && (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              padding="20px"
              gap="20px"
              alignItems={"center"}
            >
              <Grid item display="grid">
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  gap="15px"
                  alignItems={"center"}
                >
                  <Grid item xs display="grid">
                    <BasicInput
                      title="Titre"
                      colorTitle={"Black"}
                      placeholder=""
                      centerTitle={true}
                      value={step.title}
                      onChange={(e) => {
                        setStep((s) => {
                          return { ...s, title: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs display="grid">
                    <BasicSelect
                      options={[
                        {
                          value: "timer",
                          label: "Timer",
                        },
                        {
                          value: "switch",
                          label: "Switch",
                        },
                        {
                          value: "liste_responses",
                          label: "Liste",
                        },
                        {
                          value: "audio",
                          label: "Audio",
                        },
                      ]}
                      title="Type"
                      colorTitle={"Black"}
                      placeholder=""
                      centerTitle={true}
                      value={step.type}
                      onChange={(e) => {
                        if (e.target.value) {
                          setStep((l) => {
                            return { ...l, type: e.target.value as any };
                          });
                        }
                      }}
                    />
                  </Grid>
                  {["audio"].includes(step.type) && (
                    <>
                      <Grid item display="grid">
                        <input
                          type="file"
                          name="file-audio"
                          id="file-audio"
                          accept="audio/*,video/mp3"
                          style={{
                            opacity: 0,
                            zIndex: -1,
                            position: "absolute",
                          }}
                          onChange={(e) => {
                            const fileReader = new FileReader();

                            if (e?.target?.files?.[0]) {
                              if (e.target.files[0].size >= 400000000) {
                                snackbarOpenUse.error(
                                  "Le fichier est trop lourd."
                                );
                              } else if (
                                !audioTypes.includes(e.target.files[0].type)
                              ) {
                                snackbarOpenUse.error(
                                  "Le fichier est au mauvais format, ça doit être un audio."
                                );
                              } else {
                                fileReader.readAsDataURL(e.target.files[0]);
                                fileReader.onload = (event) => {
                                  const attachment = event?.target
                                    ?.result as string;
                                  const attachment_name = e?.target?.files?.[0]
                                    ?.name as string;
                                  // Add audio title
                                  setStep((l) => {
                                    return {
                                      ...l,
                                      audio: {
                                        ...l.audio,
                                        _id: nanoid(8),
                                        audio: attachment,
                                        title: attachment_name,
                                        lengthInSec: 0,
                                      },
                                    };
                                  });
                                };
                              }
                            }
                          }}
                        />
                        <label htmlFor="file-audio">
                          <NormalText
                            text={
                              step?.audio
                                ? "Mettre à jour l'audio"
                                : "Ajouter un audio"
                            }
                            fontSize="12px"
                            fontWeight={600}
                            color="Black"
                            className="button-nobreak"
                          />
                        </label>
                      </Grid>
                      <Grid item display="grid">
                        {step?.audio ? (
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            gap="20px"
                          >
                            <Grid item display="grid">
                              <DisplayAudio
                                audio={step.audio}
                                startAuto={false}
                                isEditInterface={true}
                                fitContent={true}
                              />
                            </Grid>
                            <Grid item display="grid">
                              <ButtonIconWithTooltip
                                Icon={
                                  <EditRounded
                                    style={{
                                      color: Colors.Black,
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                }
                                onClick={() => setEditAudioOpen(step.audio)}
                                tooltipText="Modifier"
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <NormalText
                            text="Aucun audio n'a été ajouté."
                            fontSize="12px"
                            color="Black"
                          />
                        )}
                      </Grid>
                    </>
                  )}
                  {["switch", "liste_responses"].includes(step.type) && (
                    <>
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="column"
                          wrap="nowrap"
                          gap="10px"
                          alignItems={"center"}
                        >
                          <Grid item display="grid">
                            <NormalText
                              text="Ajouter une question"
                              fontSize="12px"
                              fontWeight={600}
                              color="Black"
                              className="button-nobreak"
                              onClick={() => {
                                setAddQuestionOpen(true);
                              }}
                            />
                          </Grid>
                          {step.questionLabels &&
                          step.questionLabels.length > 0 ? (
                            <Grid item display="grid">
                              <Grid
                                container
                                direction="column"
                                wrap="nowrap"
                                gap="15px"
                              >
                                {step?.questionLabels &&
                                  step?.questionLabels?.length > 0 && (
                                    <DragDropContext onDragEnd={onDragEnd}>
                                      <Droppable droppableId="questions">
                                        {(provided: any) => (
                                          <Grid
                                            item
                                            display="grid"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                          >
                                            <Grid
                                              container
                                              direction="column"
                                              wrap="nowrap"
                                              gap="15px"
                                            >
                                              {step.questionLabels.map(
                                                (questionLabel, index) => (
                                                  <Draggable
                                                    key={questionLabel._id}
                                                    draggableId={
                                                      questionLabel._id as any
                                                    }
                                                    index={index}
                                                    isDragDisabled={false}
                                                  >
                                                    {(provided: any) => (
                                                      <Grid
                                                        item
                                                        display="grid"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                      >
                                                        <Grid
                                                          container
                                                          direction="row"
                                                          gap="10px"
                                                          justifyContent={
                                                            "space-between"
                                                          }
                                                          alignItems={"center"}
                                                          wrap="nowrap"
                                                        >
                                                          <Grid
                                                            item
                                                            display="grid"
                                                          >
                                                            <NormalText
                                                              text={
                                                                questionLabel?.question
                                                                  ? questionLabel.question
                                                                  : "Sans titre"
                                                              }
                                                              color="Black"
                                                              fontSize="14px"
                                                              fontWeight={400}
                                                            />
                                                          </Grid>

                                                          <Grid
                                                            item
                                                            display="grid"
                                                          >
                                                            <Grid
                                                              container
                                                              direction="row"
                                                              gap="5px"
                                                            >
                                                              <Grid
                                                                item
                                                                display="grid"
                                                              >
                                                                <ButtonIconWithTooltip
                                                                  Icon={
                                                                    <EditRounded
                                                                      style={{
                                                                        color:
                                                                          Colors.Black,
                                                                        width:
                                                                          "20px",
                                                                        height:
                                                                          "20px",
                                                                      }}
                                                                    />
                                                                  }
                                                                  onClick={() => {
                                                                    setEditQuestionOpen(
                                                                      questionLabel
                                                                    );
                                                                  }}
                                                                  tooltipText="Modifier"
                                                                />
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                display="grid"
                                                              >
                                                                <ButtonIconWithTooltip
                                                                  Icon={
                                                                    <DeleteRounded
                                                                      style={{
                                                                        color:
                                                                          Colors.Black,
                                                                        width:
                                                                          "20px",
                                                                        height:
                                                                          "20px",
                                                                      }}
                                                                    />
                                                                  }
                                                                  onClick={() => {
                                                                    setStep(
                                                                      (e) => {
                                                                        return {
                                                                          ...e,
                                                                          questionLabels:
                                                                            e.questionLabels.filter(
                                                                              (
                                                                                s
                                                                              ) =>
                                                                                s._id !==
                                                                                step._id
                                                                            ),
                                                                        };
                                                                      }
                                                                    );
                                                                  }}
                                                                  tooltipText="Supprimer"
                                                                />
                                                              </Grid>
                                                            </Grid>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    )}
                                                  </Draggable>
                                                )
                                              )}
                                              {provided.placeholder}
                                            </Grid>
                                          </Grid>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  )}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid item display="grid">
                              <NormalText
                                text="Aucune question."
                                color="Black"
                                fontSize="12px"
                                fontWeight={400}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {step.type === "timer" && (
                    <>
                      <Grid item display="grid">
                        <BasicInput
                          title="Instructions"
                          colorTitle={"Black"}
                          placeholder=""
                          centerTitle={true}
                          value={step?.timer?.instructions}
                          onChange={(e) => {
                            setStep((x) => {
                              return {
                                ...x,
                                timer: {
                                  ...x.timer,
                                  instructions: e?.target?.value
                                    ? e.target.value
                                    : "",
                                  duration: x?.timer?.duration
                                    ? x.timer.duration
                                    : 0,
                                },
                              };
                            });
                          }}
                        />
                      </Grid>
                      <Grid item display="grid">
                        <BasicInput
                          title="Durée (en secondes)"
                          colorTitle={"Black"}
                          placeholder=""
                          style={{
                            maxWidth: "300px",
                          }}
                          value={step?.timer?.duration}
                          onChange={(e) => {
                            setStep((a) => {
                              const result = e.target.value;
                              if (result) {
                                const parsed = parseInt(result);
                                if (parsed && !isNaN(parsed)) {
                                  return {
                                    ...a,
                                    timer: {
                                      ...a.timer,
                                      instructions: a?.timer?.instructions
                                        ? a.timer.instructions
                                        : "",
                                      duration: parsed,
                                    },
                                  };
                                }
                              }
                              return {
                                ...a,
                                timer: {
                                  ...a.timer,
                                  instructions: a?.timer?.instructions
                                    ? a.timer.instructions
                                    : "",
                                  duration: 0,
                                },
                              };
                            });
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item display="grid" marginTop="25px">
                  <Grid
                    container
                    direction="row"
                    gap="10px"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Grid item display="grid">
                      <SecondaryButton
                        text={"Annuler"}
                        onClick={() => {
                          if (props.onClose) {
                            props.onClose();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <PrimaryButton
                        text={props.isEdit ? "Modifier" : "Créer"}
                        onClick={() => {
                          if (props.onSubmit) {
                            props.onSubmit(step);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      </GenericPopup>
    </>
  );
};

const CreateOrEditExercise: React.FC<{
  exercise?: IExercise;
  isEdit?: boolean;
  onSubmit: (newExercise: IExercise) => void;
  onClose?: () => void;
}> = (props) => {
  const [addStepOpen, setAddStepOpen] = React.useState(false);
  const [editStepOpen, setEditStepOpen] = React.useState<
    ExerciseStep | undefined
  >(undefined);
  const [editStartAudioOpen, setEditStartAudioOpen] = React.useState<
    Audio | undefined
  >(undefined);
  const [exercise, setExercise] = React.useState<IExercise>(initExercise());
  const snackbarOpenUse = useOpenSnackbar();

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(exercise.steps);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setExercise((prevExercise) => ({
      ...prevExercise,
      steps: items,
    }));
  };

  React.useEffect(() => {
    if (props.isEdit && props.exercise) {
      setExercise(props.exercise);
    } else {
      return;
    }
  }, [props.isEdit, props.exercise]);

  if (!exercise) {
    return <></>;
  }
  return (
    <>
      <GenericPopup
        title={{
          leftPart: props.isEdit ? "Modifier" : "Créer",
          rightPart: "mon exercice",
        }}
        onClose={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <>
          {editStartAudioOpen && (
            <EditAudio
              audio={editStartAudioOpen}
              onClose={() => {
                setEditStartAudioOpen(undefined);
              }}
              onSubmit={(newAudio) => {
                setExercise((e) => {
                  return {
                    ...e,
                    startAudio: newAudio,
                  };
                });
              }}
            />
          )}
          {addStepOpen && (
            <CreateOrEditStep
              onSubmit={(newStep) => {
                setExercise((state) => {
                  return {
                    ...state,
                    steps:
                      !state.steps || state.steps.length === 0
                        ? [newStep]
                        : [...state.steps, newStep],
                  };
                });
                setAddStepOpen(false);
              }}
              step={undefined}
              isEdit={false}
              onClose={() => setAddStepOpen(false)}
            />
          )}
          {editStepOpen && (
            <CreateOrEditStep
              onSubmit={(newStep) => {
                setExercise((state) => {
                  return {
                    ...state,
                    steps:
                      state.steps && state.steps.length > 0
                        ? state.steps.map((step) => {
                            if (step?._id !== newStep?._id) {
                              return step;
                            }
                            return newStep;
                          })
                        : [],
                  };
                });
                setEditStepOpen(undefined);
              }}
              step={editStepOpen}
              isEdit={true}
              onClose={() => setEditStepOpen(undefined)}
            />
          )}
          {exercise && (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              padding="20px"
              gap="20px"
              alignItems={"center"}
            >
              <Grid
                container
                direction="row"
                gap="20px"
                justifyContent={"space-evenly"}
              >
                <Grid item display="grid">
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    gap="15px"
                    alignItems={"flex-start"}
                  >
                    <Grid item xs display="grid">
                      <BasicInput
                        title="Titre"
                        colorTitle={"Black"}
                        placeholder=""
                        centerTitle={true}
                        value={exercise.title}
                        onChange={(e) => {
                          setExercise((l) => {
                            return { ...l, title: e.target.value };
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs display="grid">
                      <BasicInput
                        title="Description"
                        colorTitle={"Black"}
                        placeholder=""
                        centerTitle={true}
                        value={exercise.description}
                        onChange={(e) => {
                          setExercise((l) => {
                            return { ...l, description: e.target.value };
                          });
                        }}
                      />
                    </Grid>
                    <Grid item display="grid">
                      <BasicInput
                        title="Durée de l'exercice (en sec)"
                        centerTitle={true}
                        colorTitle={"Black"}
                        placeholder=""
                        style={{
                          maxWidth: "300px",
                        }}
                        value={exercise.durationEstimation}
                        onChange={(e) => {
                          setExercise((a) => {
                            const result = e.target.value;
                            if (result) {
                              const parsed = parseInt(result);
                              if (parsed && !isNaN(parsed)) {
                                return {
                                  ...a,
                                  durationEstimation: parsed,
                                };
                              }
                            }
                            return {
                              ...a,
                              durationEstimation: 0,
                            };
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs display="grid">
                      <BasicInput
                        title="Message de fin d'exercice"
                        centerTitle={true}
                        colorTitle={"Black"}
                        placeholder=""
                        value={exercise.congratulationMessage}
                        onChange={(e) => {
                          setExercise((l) => {
                            return {
                              ...l,
                              congratulationMessage: e.target.value,
                            };
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item display="grid">
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    gap="10px"
                    alignItems={"center"}
                  >
                    <Grid item display="grid">
                      <input
                        type="file"
                        name="file-audio"
                        id="file-audio"
                        accept="audio/*,video/mp3"
                        style={{
                          opacity: 0,
                          zIndex: -1,
                          position: "absolute",
                        }}
                        onChange={(e) => {
                          const fileReader = new FileReader();

                          if (e?.target?.files?.[0]) {
                            if (e.target.files[0].size >= 400000000) {
                              snackbarOpenUse.error(
                                "Le fichier est trop lourd."
                              );
                            } else if (
                              !audioTypes.includes(e.target.files[0].type)
                            ) {
                              snackbarOpenUse.error(
                                "Le fichier est au mauvais format, ça doit être un audio."
                              );
                            } else {
                              fileReader.readAsDataURL(e.target.files[0]);
                              fileReader.onload = (event) => {
                                const attachment = event?.target
                                  ?.result as string;
                                const attachment_name = e?.target?.files?.[0]
                                  ?.name as string;
                                // Add audio title
                                setExercise((l) => {
                                  return {
                                    ...l,
                                    startAudio: {
                                      ...l.startAudio,
                                      _id: nanoid(8),
                                      audio: attachment,
                                      title: attachment_name,
                                      lengthInSec: 0,
                                    },
                                  };
                                });
                              };
                            }
                          }
                        }}
                      />
                      <label htmlFor="file-audio">
                        <NormalText
                          text={
                            exercise?.startAudio?.audio
                              ? "Mettre à jour l'audio initial"
                              : "Ajouter l'audio initial"
                          }
                          fontSize="12px"
                          fontWeight={600}
                          color="Black"
                          className="button-nobreak"
                        />
                      </label>
                    </Grid>
                    <Grid item display="grid">
                      {exercise?.startAudio?.audio ? (
                        <Grid
                          container
                          direction="row"
                          wrap="nowrap"
                          gap="20px"
                        >
                          <Grid item display="grid">
                            <DisplayAudio
                              audio={exercise.startAudio}
                              startAuto={false}
                              isEditInterface={true}
                              fitContent={true}
                            />
                          </Grid>
                          <Grid item display="grid">
                            <ButtonIconWithTooltip
                              Icon={
                                <EditRounded
                                  style={{
                                    color: Colors.Black,
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              }
                              onClick={() =>
                                setEditStartAudioOpen(exercise.startAudio)
                              }
                              tooltipText="Modifier"
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <NormalText
                          text="Aucun audio n'a été ajouté."
                          fontSize="12px"
                          color="Black"
                        />
                      )}
                    </Grid>
                    <Grid item display="grid" marginTop="30px">
                      <NormalText
                        text="Ajouter une étape"
                        fontSize="12px"
                        fontWeight={600}
                        color="Black"
                        className="button-nobreak"
                        onClick={() => {
                          setAddStepOpen(true);
                        }}
                      />
                    </Grid>
                    {exercise.steps && exercise.steps.length > 0 ? (
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="column"
                          wrap="nowrap"
                          gap="15px"
                        >
                          {exercise?.steps?.length > 0 && (
                            <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable droppableId="steps">
                                {(provided: any) => (
                                  <Grid
                                    item
                                    display="grid"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    <Grid
                                      container
                                      direction="column"
                                      wrap="nowrap"
                                      gap="15px"
                                    >
                                      {exercise.steps.map((step, index) => (
                                        <Draggable
                                          key={step._id}
                                          draggableId={step._id as any}
                                          index={index}
                                          isDragDisabled={false}
                                        >
                                          {(provided: any) => (
                                            <Grid
                                              item
                                              display="grid"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <Grid
                                                container
                                                direction="row"
                                                gap="10px"
                                                justifyContent={"space-between"}
                                                alignItems={"center"}
                                                wrap="nowrap"
                                              >
                                                <Grid item display="grid">
                                                  <NormalText
                                                    text={
                                                      step?.title
                                                        ? step.title
                                                        : "Sans titre"
                                                    }
                                                    color="Black"
                                                    fontSize="14px"
                                                    fontWeight={400}
                                                  />
                                                </Grid>

                                                <Grid item display="grid">
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    gap="5px"
                                                  >
                                                    <Grid item display="grid">
                                                      <ButtonIconWithTooltip
                                                        Icon={
                                                          <EditRounded
                                                            style={{
                                                              color:
                                                                Colors.Black,
                                                              width: "20px",
                                                              height: "20px",
                                                            }}
                                                          />
                                                        }
                                                        onClick={() => {
                                                          setEditStepOpen(step);
                                                        }}
                                                        tooltipText="Modifier"
                                                      />
                                                    </Grid>
                                                    <Grid item display="grid">
                                                      <ButtonIconWithTooltip
                                                        Icon={
                                                          <DeleteRounded
                                                            style={{
                                                              color:
                                                                Colors.Black,
                                                              width: "20px",
                                                              height: "20px",
                                                            }}
                                                          />
                                                        }
                                                        onClick={() => {
                                                          setExercise((e) => {
                                                            return {
                                                              ...e,
                                                              steps:
                                                                e.steps.filter(
                                                                  (s) =>
                                                                    s._id !==
                                                                    step._id
                                                                ),
                                                            };
                                                          });
                                                        }}
                                                        tooltipText="Supprimer"
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </Grid>
                                  </Grid>
                                )}
                              </Droppable>
                            </DragDropContext>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item display="grid">
                        <NormalText
                          text="Aucune étape."
                          color="Black"
                          fontSize="12px"
                          fontWeight={400}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item display="grid" marginTop="10px">
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  gap="10px"
                  alignItems={"center"}
                >
                  <Grid item display="grid">
                    <input
                      type="checkbox"
                      checked={exercise?.needSubscription === true}
                      onClick={(e) => {
                        setExercise((x) => {
                          return {
                            ...x,
                            needSubscription: x?.needSubscription
                              ? false
                              : true,
                          };
                        });
                      }}
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <NormalText
                      text={"Cet exercice requiert un abonnement."}
                      color="Black"
                      fontSize="14px"
                      fontWeight={400}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item display="grid" marginTop="10px">
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  gap="10px"
                  alignItems={"center"}
                >
                  <Grid item display="grid">
                    <input
                      type="checkbox"
                      checked={exercise?.isPopular === true}
                      onClick={(e) => {
                        setExercise((x) => {
                          return {
                            ...x,
                            isPopular: x?.isPopular
                              ? false
                              : true,
                          };
                        });
                      }}
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <NormalText
                      text={"Mettre en avant l'exercice dans la page d'accueil."}
                      color="Black"
                      fontSize="14px"
                      fontWeight={400}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item display="grid" marginTop="15px">
                <Grid
                  container
                  direction="row"
                  gap="10px"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item display="grid">
                    <SecondaryButton
                      text={"Annuler"}
                      onClick={() => {
                        if (props.onClose) {
                          props.onClose();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <PrimaryButton
                      text={props.isEdit ? "Modifier" : "Créer"}
                      onClick={() => {
                        if (props.onSubmit) {
                          props.onSubmit(exercise);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      </GenericPopup>
    </>
  );
};

const Exercices: React.FC = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((x) => x?.user?.user?.role);
  const isAdmin = role === "admin" ? true : false;
  const snackbarUse = useOpenSnackbar();
  const [createExerciseOpen, setCreateExerciseOpen] =
    React.useState<boolean>(false);
  const [editExerciseOpen, setEditExerciseOpen] = React.useState<
    IExercise | undefined
  >(undefined);
  const exercises = useAppSelector((x) => x?.exercises?.exercises);
  const pageLoading = useAppSelector((x) => x.exercises.isLoading);

  React.useEffect(() => {
    dispatch(getAllExercises())
      .unwrap()
      .then((res) => {
        return;
      })
      .catch((err) => {
        snackbarUse.error("Impossible de récupérer les exercices.");
      })
      .finally(() => {
        return;
      });
  }, [dispatch]);

  return (
    <Page pageTitle="Exercices">
      {editExerciseOpen && (
        <CreateOrEditExercise
          isEdit={true}
          exercise={editExerciseOpen}
          onClose={() => setEditExerciseOpen(undefined)}
          onSubmit={(exerciseToEdit) => {
            console.log(exerciseToEdit);
            if (exerciseToEdit._id) {
              dispatch(
                updateExercise({
                  data: cleanExerciseBeforePut(exerciseToEdit),
                  id: exerciseToEdit._id,
                })
              )
                .unwrap()
                .then((res) => {
                  snackbarUse.success("Exercice modifiée avec succès.", res);
                  setEditExerciseOpen(undefined);
                })
                .catch((err) => {
                  snackbarUse.error(
                    "Impossible de mettre à jour cet exercice.",
                    err
                  );
                });
            }
          }}
        />
      )}
      {createExerciseOpen && (
        <CreateOrEditExercise
          isEdit={false}
          onClose={() => setCreateExerciseOpen(false)}
          onSubmit={(newExercise) => {
            dispatch(createExercise(cleanExerciseBeforeSend(newExercise)))
              .unwrap()
              .then((res) => {
                snackbarUse.success("Exercice créé avec succès.", res);
                setCreateExerciseOpen(false);
              })
              .catch((err) => {
                snackbarUse.error("Impossible de créer cet exercice.", err);
              });
          }}
        />
      )}
      {isAdmin && (
        <div
          style={{
            position: "absolute",
            right: 50,
            bottom: 90,
            zIndex: 9,
          }}
        >
          <div
            onClick={(e) => {
              setCreateExerciseOpen(true);
            }}
            style={{
              background: `rgba(65, 65, 65, 1)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "45px",
              height: "45px",
              borderRadius: "50%",
              zIndex: 1,
              cursor: "pointer",
              border: "1px dotted #fff",
            }}
          >
            <ButtonIconWithTooltip
              tooltipText="Créer un exercice"
              Icon={
                <AddRounded
                  style={{
                    color: "#fff",
                    height: "38px",
                    width: "38px",
                  }}
                />
              }
              onClick={() => {
                return;
              }}
            />
          </div>
        </div>
      )}
      <Grid container direction="column" wrap="nowrap" gap="30px">
        <Grid item display="grid">
          <NormalText text="Exercices" />
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            gap="20px"
            justifyContent={"space-evenly"}
          >
            {pageLoading ? (
              <Grid item display="grid">
                <CircularProgress
                  style={{
                    color: "white",
                  }}
                />
              </Grid>
            ) : (
              <>
                {exercises.length === 0 ? (
                  <Grid item display="grid" paddingTop="20px">
                    <NormalText
                      text="Il n'y a aucun exercice pour le moment."
                      fontSize="16px"
                      color="White"
                    />
                  </Grid>
                ) : (
                  <>
                    {exercises.map((exercise, index, all) => {
                      return (
                        <Grid item key={exercise._id} display="grid">
                          <DrawExerciseCard
                            onLeftClick={
                              index === 0
                                ? undefined
                                : () => {
                                    const thisCardId = exercise._id;
                                    const leftCardId = all[index - 1]._id;
                                    if (thisCardId && leftCardId) {
                                      dispatch(
                                        swapExercises({
                                          firstId: thisCardId,
                                          secondId: leftCardId,
                                        })
                                      )
                                        .unwrap()
                                        .then((res) => {
                                          snackbarUse.success(
                                            "L'ordre des exercices a bien été modifié.",
                                            res
                                          );
                                        })
                                        .catch((err) => {
                                          snackbarUse.error(
                                            "Impossible de modifier l'ordre des exercices.",
                                            err
                                          );
                                        });
                                    }
                                  }
                            }
                            onRightClick={
                              index === all?.length - 1
                                ? undefined
                                : () => {
                                    const thisCardId = exercise._id;
                                    const rightCardId = all[index + 1]._id;
                                    if (thisCardId && rightCardId) {
                                      dispatch(
                                        swapExercises({
                                          firstId: thisCardId,
                                          secondId: rightCardId,
                                        })
                                      )
                                        .unwrap()
                                        .then((res) => {
                                          snackbarUse.success(
                                            "L'ordre des exercices a bien été modifié.",
                                            res
                                          );
                                        })
                                        .catch((err) => {
                                          snackbarUse.error(
                                            "Impossible de modifier l'ordre des exercices.",
                                            err
                                          );
                                        });
                                    }
                                  }
                            }
                            card={exercise}
                            onEditClick={() => {
                              setEditExerciseOpen(exercise);
                            }}
                            onDeleteClick={() => {
                              return;
                            }}
                            onFavoriteClick={() => {
                              if (exercise?._id) {
                                dispatch(clickFavoriteExercise(exercise._id));
                              }
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Exercices;
